import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "visualizacao-de-dados",
      "style": {
        "position": "relative"
      }
    }}>{`Visualização de dados`}</h1>
    <p>{`A visualização de dados consiste em apresentar graficamente informações e dados para sintetizar informações complexas e compreender padrões e exceções apresentados nos dados. A visualização de dados não deve ser usada como decoração.`}</p>
    <TabLinks items={[{
      title: 'Modelos',
      href: '/design-guidelines/data-visualization/models'
    }, {
      title: 'Uso',
      href: '/design-guidelines/data-visualization/usage'
    }]} mdxType="TabLinks" />
    <h3 {...{
      "id": "grafico-de-linha",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de linha`}</h3>
    <p>{`Usados para apresentar tendências ao longo do tempo e para comparar diversas séries de dados. Os valores são mostrados em pontos conectados por linhas, como dados contínuos.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/line-chart.png",
        "alt": "Line chart"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/image/line-chart-multiple.png",
        "alt": "Multiple line chart"
      }}></img></p>
    <h4 {...{
      "id": "variacao-grafico-de-area",
      "style": {
        "position": "relative"
      }
    }}>{`Variação: Gráfico de área`}</h4>
    <p>{`Usados para apresentar tendências ao longo do tempo e para comparar diversas séries de dados, nos quais é importante ver a sobreposição.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/area-chart.png",
        "alt": "Area chart"
      }}></img></p>
    <h3 {...{
      "id": "grafico-de-barras",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de barras`}</h3>
    <p>{`Em gráficos que são mensurados em escalas nominais (qualitativas), é recomendado apresentar as barras na posição horizontal. Isso facilita uma rápida visualização das categorias, que podem ser identificadas ao lado ou no interior de cada barra.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/bar-chart.png",
        "alt": "Bar chart"
      }}></img></p>
    <h3 {...{
      "id": "grafico-de-colunas",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de colunas`}</h3>
    <p>{`Utilizam colunas para comparar dados discretos, categóricos, ou comparar tendências ao longo do tempo (série histórica). A barra é apresentada na vertical e corresponde à sua medida de valor numérico.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/column-chart.png",
        "alt": "Column chart"
      }}></img></p>
    <h3 {...{
      "id": "grafico-de-barras-ou-colunas-empilhadas",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de barras ou colunas empilhadas`}</h3>
    <p>{`Utilizados para comparar a proporção de contribuição ao valor total dentro de uma escala.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/stacked-chart.png",
        "alt": "Stacked column chart"
      }}></img></p>
    <h3 {...{
      "id": "grafico-de-pizza",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de pizza`}</h3>
    <p>{`Usado para mostrar uma visualização de parte para todo. Ao comparar dados por ângulos, os gráficos de pizza não costumam ser as opções mais fáceis para analisar dados quantitativos (considere o uso de Gráfico de barras ou Gráfico de colunas). Preste atenção ao número de itens, pois esses gráficos não acomodam um grande número de categorias.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/circular-chart.png",
        "alt": "Pie chart"
      }}></img></p>
    <h3 {...{
      "id": "grafico-de-controle",
      "style": {
        "position": "relative"
      }
    }}>{`Gráfico de controle`}</h3>
    <p>{`Gráficos de controle são usados em processos para se acompanhar a evolução de uma variável em relação a uma ou várias referências de valor.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/control-line-chart.png",
        "alt": "Control line chart"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/image/control-area-chart.png",
        "alt": "Control area chart"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      